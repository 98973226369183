import { Card, CardContent, Typography } from "@mui/material";

const NoDocumentCard = () => {
    return (
        <Card sx={{flexBasis: 600}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Helaas
                </Typography>
                <Typography  sx={{ mb: 1.5 }} variant="h5" component="div">
                    Geen investeringskansen beschikbaar
                </Typography>
                <Typography variant="body2">
                    Momenteel zijn er geen investeringskansen voor u beschikbaar. Houd uw mailbox in de gaten voor updates.
                </Typography>
            </CardContent>
        </Card>
    )
}

export default NoDocumentCard;