import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { userHasGroup } from '../../utils/authUtil';

import './Header.scss';

const Header = () => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        try{ 
            Auth.currentAuthenticatedUser().then(user => {
                setIsAdmin(userHasGroup(user, 'Admins'))
            });
        }
        catch{
            setIsAdmin(false);
        }
    },[])

    return (
        <div className='Header'>
            <div className="Header__nav">
                <NavLink to='/'>Home</NavLink>
                <NavLink to='/portal'>Portal</NavLink>
                {isAdmin && <NavLink to='/admin/user/create'>Admin</NavLink>}
                <NavLink to='/contact'>Contact</NavLink>
            </div>
            <div className="Header__logo">
                <Link to='/'>
                    <img src="/img/ikbenjames.png" alt="Ik ben James logo" />
                </Link>
            </div>
        </div>
    );
};

export default Header;