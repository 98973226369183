import React from 'react';
import Header from '../Components/Header';
import Jumbotron from '../Components/Jumbotron';

import './Contact.scss'

const Contact = () => {
    return (
        <div className="Wrapper" style={{backgroundImage: `url('/img/waagplein.jpg')`, minHeight: '100vh'}}>
            <div className="Page">
                <Header/>
                <Jumbotron title="Contactgegevens">
                    <p>Wij verzoeken u per email contact op te nemen via <a href="mailto:hallo@ikbenjames.com">hallo@ikbenjames.com</a>.</p>
                </Jumbotron>
            </div>
        </div>
    );
};

export default Contact;