import { DocumentActions } from '../actions/documents'

const initialState = {
    documents: [],
    initialized: false,
}

export const documents = (state = initialState, action) => {
    switch (action.type) {
        case DocumentActions.setDocuments:
            return {...state, documents: action.data}
        case DocumentActions.addDocument:
            return {...state, documents: [...state.documents, action.data]}
        case DocumentActions.removeDocument:
            return {...state, documents: state.documents.filter(document => document.id !== action.data.id)}
        case DocumentActions.setDocumentInitializationStatus:
            return {...state, initialized: action.data}
        default:
            return state
    }
}
