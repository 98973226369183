import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";

export const useCustomSignOut = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({type: 'USER_SIGNOUT'})
        Auth.signOut();
        localStorage.clear();
    }
}