import React from 'react';

import './Jumbotron.scss';

const Jumbotron = ({title, subtitle = null, children, image, style={}}) => {

    return (
        <div className={`Jumbotron`} style={style}>
            <div className="Jumbotron__content-wrapper">
            <div className="Jumbotron__title">
                { subtitle && <span>{subtitle}</span> }
                <h1>{title}</h1>
            </div>
            {children}
            </div>
            {
                image && 
                    <div className="Jumbotron__image">
                        <img src={image} alt={title} />
                    </div>
            }
        </div>
    );
};

export default Jumbotron;