import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DocumentCard = ({document}) => {
    const navigate = useNavigate();

    const navigateToDocument = () => {
        navigate(`/document/${document.documentId}`);
    }

    // Limit the length of the title to 30 characters, and add an ellipsis if it's longer
    const title = document.title.length > 30 ? document.title.substring(0, 30) + '...' : document.title;

    // Limit the length of the description to 100 characters, and add an ellipsis if it's longer
    const description = document.description.length > 100 ? document.description.substring(0, 100) + '...' : document.description;

    const expiresAt = new Date(document.expiresAt);
    const expiresAtFormatted = expiresAt.toLocaleDateString();

    return (
        <Card sx={{flexBasis: 350}} className="DocumentCard">
            <CardActionArea onClick={navigateToDocument}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {expiresAtFormatted}
                    </Typography>
                    <Typography  sx={{ mb: 1.5 }} variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default DocumentCard;