import { Auth } from "aws-amplify";

let endpoints = {
    JamesHttpApi: "https://qdtf3ucil2.execute-api.eu-central-1.amazonaws.com"
}

// Check if environment is development
if (process.env.NODE_ENV === "development") {
    console.log("Using development environment")
    endpoints = {
        JamesHttpApi: "https://02p8d2e620.execute-api.eu-central-1.amazonaws.com"
    }
} else if (process.env.NODE_ENV === "production") {
    console.log("Using production environment")
    endpoints = {
        JamesHttpApi: "https://4glcomk4vc.execute-api.eu-central-1.amazonaws.com"
    }
}

export const amplify_config = {
    Auth: {
        identityPoolId: 'eu-central-1:4eefc044-e524-4526-afda-7fb80980ad64', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'eu-central-1', // REQUIRED - Amazon Cognito Region
        userPoolId: 'eu-central-1_5y0CiEFjD', //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '511e881quu4s9h8dqk6ec6k9ts', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
        AWSS3: {
            bucket: 'james-document-bucket', //REQUIRED -  Amazon S3 bucket name
            region: 'eu-central-1', //OPTIONAL -  Amazon service region
        }
    },   
    API: {
        endpoints: [
            {
                name: "JamesHttpApi",
                endpoint: endpoints.JamesHttpApi,
                region: "eu-central-1",
                custom_header: async () => {
                    const user = await Auth.currentAuthenticatedUser();
                    return {
                        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
                    }
                }
            }
        ]
    }
}
