import { API } from "aws-amplify";
import { setDocumentInitializationStatus, setDocuments } from "../store/actions/documents";
import { setDocumentsOfUser, setDocumentsOfUserInitializationStatus, updateDocumentOfUser } from "../store/actions/documentsOfUser";
import { setUserInitializationStatus, setUsers } from "../store/actions/users";
import { store } from "../store/createStore";

export async function getUserList() { 
  const apiName = 'JamesHttpApi';
  const path = '/admin/users';
  const myInit = { 
    headers: { 
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE",
      "'Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token'"
    }
  };

  return await API.get(apiName, path, myInit);
}

export async function getUserDocumentList() {
  const path = '/documents';
  return get(path);
}

export async function getUserDocument(documentId) {
  const path = `/documents/${documentId}`;
  const data = await get(path);
  const document = mapDocument(data);

  // Update document in store
  store.dispatch(updateDocumentOfUser(document));
  return document;
}

export async function getAdminDocumentList() {
  const path = '/admin/documents';
  return get(path);
}

const mapDocument = (document) => {
  return {
    documentId: document.DocumentId,
    title: document.Title,
    description: document.Description,
    status: document["Status#ExpiresAt"].split("#")[0],
    expiresAt: document["Status#ExpiresAt"].split("#")[1],
    filePath: document.FilePath,
    presignedUrl: document.PresignedUrl || "",
  }
}

export const initializeDocumentStore =  async () => {
  await getAdminDocumentList().then(data => {
    // TODO: parse status and expires at 
    const documents = data.map(document => mapDocument(document));
    store.dispatch(setDocuments(documents));
    store.dispatch(setDocumentInitializationStatus(true));
  });
}

export const initializeDocumentsOfUserStore =  async () => {
  await getUserDocumentList().then(data => {
    const documentsOfUser = data.map(document => mapDocument(document));
    store.dispatch(setDocumentsOfUser(documentsOfUser))
    store.dispatch(setDocumentsOfUserInitializationStatus(true));
  });
};

export const initializeUserStore =  async () => {
  await getUserList().then(list => {
    let thisUserList = list.Users.map( e => {
        let thisAttributes = e.Attributes.reduce((prev, cur) => {return {[cur.Name]:cur.Value, ...prev}},{});
        let thisEntry = {...e, ...thisAttributes};
        return thisEntry})
        store.dispatch(setUsers(thisUserList))
        store.dispatch(setUserInitializationStatus(true));
      });
}

export const post = async (path, body) => {
  const apiName = 'JamesHttpApi';
  const myInit = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE",
      "'Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token'"
    },
    body: body
  };
  return await API.post(apiName, path, myInit);
}

export const put = async (path, body) => {
  const apiName = 'JamesHttpApi';
  const myInit = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE",
      "'Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token'"
    },
    body: body
  };
  return await API.put(apiName, path, myInit);
}

export const get = async (path, apiName = 'JamesHttpApi') => {
  const headers = getApiHeaders();

  return await API.get(apiName, path, {
    headers: headers
  });
}

const getApiHeaders = async () => {
  return {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods":"GET, POST, PUT, DELETE",
    "'Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token'",
  };
}