import { DocumentsOfUserActions } from '../actions/documentsOfUser'

const initialState = {
    documentsOfUser: [],
    initialized: false,
}

export const documentsOfUser = (state = initialState, action) => {
    switch (action.type) {
        case DocumentsOfUserActions.setDocumentsOfUser:
            return {...state, documentsOfUser: action.data}
        case DocumentsOfUserActions.updateDocumentOfUser:
            const newDocumentsOfUser = state.documentsOfUser.map(document => {
                if (document.documentId === action.data.documentId) {
                    return action.data
                }
                return document
            })
            return {...state, documentsOfUser: newDocumentsOfUser}
        
        case DocumentsOfUserActions.setDocumentsOfUserInitializationStatus:
            return {...state, initialized: action.data}
        default:
            return state
    }
}
