export const DocumentsOfUserActions = {
    setDocumentsOfUser: '@documents/setDocumentsOfUser',
    updateDocumentOfUser: '@documents/updateDocumentOfUser',
    setDocumentsOfUserInitializationStatus:'@documents/setDocumentsOfUserInitializationStatus'
}

export const setDocumentsOfUser = (documentsOfUser) => {
    return dispatch => dispatch({
        type: DocumentsOfUserActions.setDocumentsOfUser,
        data: documentsOfUser
    })
}

export const updateDocumentOfUser = (document) => {
    return dispatch => dispatch({
        type: DocumentsOfUserActions.updateDocumentOfUser,
        data: document
    })
}

export const setDocumentsOfUserInitializationStatus = (initialized) => {
    return dispatch => dispatch({
        type: DocumentsOfUserActions.setDocumentsOfUserInitializationStatus,
        data: initialized
    })
}
