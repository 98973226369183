import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Jumbotron from '../Components/Jumbotron';

import './Home.scss';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className='Home' style={{backgroundImage: `url('/img/waagplein.jpg')`, minHeight: '100vh'}}>
            <div className="Page">
                <Header/>
                <Jumbotron title="Ik ben James" subtitle="Hallo!" withBackground>
                    <p>James is jouw investeringsbutler en reikt investeringskansen aan op een besloten platform voor investeerders, simpel, direct en no nonsens.</p>
                    <p>Via james worden investeerders op de hoogte gehouden van nieuwe investeringskansen. Deze kansen zijn allemaal voorbereid door specialisten.</p>
                    <p>In een overzichtelijke portal kunnen investeerders meer informatie vinden over investeringskansen die voor hen worden geselecteerd.</p>
                    <div className="Button__list">
                        {/* <Button variant="contained" target="_blank" href="mailto:hallo@ikbenjames.com?subject=Aanmelden%20voor%20Ik%20ben%20James.&body=Beste%2C%0D%0A%0D%0AIk%20meld%20mij%20graag%20aan%20voor%20Ik%20ben%20james!%0D%0A%0D%0AGroet%2C%0D%0A">
                            Meld je aan
                        </Button> */}
                        <Button variant="contained" color="secondary" onClick={() => navigate('/portal')}>
                            Ga naar de portal
                        </Button>
                    </div>
                </Jumbotron>
            </div>
        </div>
    );
};

export default Home;