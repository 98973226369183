import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { documents, users, documentsOfUser } from './reducers'

const combinedReducer = combineReducers({documents, users, documentsOfUser})

const rootReducer = (state,action) => {
    if (action.type === "USER_SIGNOUT"){
        return combinedReducer(undefined, action)
    }
    else{
        return combinedReducer(state,action)
    }
}


export const store = configureStore({reducer: rootReducer})
