import React from 'react';
import { useSelector } from 'react-redux';
import DocumentCard from '../DocumentCard';
import NoDocumentCard from '../DocumentCard/NoDocumentCard';

import './DocumentList.scss';

const DocumentList = () => {
    const documents = useSelector(state => state.documentsOfUser.documentsOfUser)

    return (
        <div className="DocumentList">
            {
                documents.length > 0 ?
                    documents.map(document => (
                        <DocumentCard key={document.documentId} document={document} />
                    ))
                : <NoDocumentCard/>
            }
        </div>
    );
};

export default DocumentList;