import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { post } from '../../utils/api';

export default function AlertForm() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [showErrors, setShowErrors] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAndCreateUser = () => {
    setShowErrors(true);
    if (name && email && companyName) {
        setShowErrors(false);
        
        post('/admin/users', {name:name,email:email,company_name:companyName})
          .then(result => {
              toast.success('User created succesfully')
          }).catch(err => { toast.error(`Error when creating user: ${err.response.data.message}`)})
        setOpen(false);
    }
  };

  return (
    <div>
      <Button sx={{ m: 1 }} type="submit" variant="contained" color="primary" onClick={handleClickOpen}>
        Create account
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Users will get sent a single-sign on mail.
          </DialogContentText>
                <TextField
                    sx={{m:1, width: '100%'}}
                    label="Email" 
                    variant="outlined" 
                    value={email}
                    error={showErrors && !email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                <TextField
                    sx={{m:1, width: '100%'}}
                    label="Name" 
                    variant="outlined" 
                    value={name}
                    error={showErrors && !name}
                    onChange={(e) => setName(e.target.value)}
                    />
                <TextField
                    sx={{m:1, width: '100%'}}
                    label="Company Name" 
                    variant="outlined" 
                    value={companyName}
                    error={showErrors && !companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCloseAndCreateUser}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
