import React from 'react';
import Sidebar from '../../../Components/Sidebar';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AlertForm from '../../../Components/AlertForm';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import StatusChip from '../../../Components/StatusChip';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import ArchiveIcon from '@mui/icons-material/Archive';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';

const userStatusMapper = (userStatus) => {
    if (userStatus === "CONFIRMED"){
        return {status:userStatus, statusToDisplay: 'Confirmed', icon: <ThumbUpAltIcon/>, color:"success",tooltipText :"User has been confirmed."}
    }
    else if (userStatus === "FORCE_CHANGE_PASSWORD"){
        return {status:userStatus, statusToDisplay: "OTP sent", icon: <SendIcon/>, color:"info",tooltipText :"The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else."}
    }
    else if (userStatus === "EXTERNAL_PROVIDER"){
        return {status:userStatus, statusToDisplay: "External Provider", icon: <LinkIcon/>, color:"info",tooltipText :"User signed in with a third-party IdP."}
    }
    else if (userStatus === "ARCHIVED"){
        return {status:userStatus, statusToDisplay: "Archived", icon: <ArchiveIcon/>, color:"warning",tooltipText :"User is no longer active."}
    }
    else if (userStatus === "UNKNOWN"){
        return {status:userStatus, statusToDisplay: "Unknown", icon: <QuestionMarkIcon/>, color:"info",tooltipText :"User status isn't known."}
    }
    else if (userStatus === "RESET_REQUIRED"){
        return {status:userStatus, statusToDisplay: "Reset Required", icon: <RestartAltIcon/>, color:"info",tooltipText:"User is confirmed, but the user must request a code and reset their password before they can sign in"}
    }
    else if (userStatus === "UNCONFIRMED"){
        return {status:userStatus, statusToDisplay: "Unconfirmed", icon: <CancelIcon/>, color:"warning",tooltipText :"User has been created but not confirmed."}
    }
    return {status: userStatus, valueToDisplay: userStatus, icon: <div/>}
}

const columns = [
    { field: 'Username', headerName: 'ID', width: 130 },
    { field: 'name', headerName: 'Name', width: 200 },
    {
        field: "email",
        headerName: "Email",
        width: 200,
        renderCell: (params) => {
            return (
                <a href={`mailto:${params.formattedValue}`} target="_top">{`${params.formattedValue}`}</a>)
        }
      },
    { field: 'custom:company_name', headerName: 'Company Name', width: 200},
    {
        field:"UserStatus",
        align:"center",
        headerName:"Account creation status",
        width:150,
        renderCell({ row }){
           return <StatusChip {...userStatusMapper(row.UserStatus)} />
        }
     },
     {
        field:"Enabled",
        headerName:"Enabled status",
        align:"center",
        width:150,
        renderCell({ row }){
           return <>{row.Enabled ? <Tooltip placement="right" title="This user can log-in."><CheckIcon/></Tooltip> : <Tooltip placement="right" title="This user cannot log-in."><BlockIcon/></Tooltip>}</>
        }
     },
  ];

const UserList = () => {
    const userList = useSelector(state => state.users.users)

    let navigate = useNavigate(); 
    const routeChangeToUserPage = (userId) => { 
        let path = `/admin/user/${userId}`; 
        navigate(path);
    }
    
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box sx= {{height: 500, flex: 1}}>
                <DataGrid 
                    getRowId = {(row) => row.Username}
                    rows = {userList}
                    columns = {columns}
                    pagesize={2}
                    rowsPerPageOptions={[5]}
                    onRowDoubleClick={e => routeChangeToUserPage(e.id)}
                    components={{ Toolbar: GridToolbar }}
                />
            <AlertForm/>
            </Box>
        </Box>
    );
};

export default UserList;