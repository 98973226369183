import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AlertDialogButton from '../../../Components/AlertDialogButton';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Sidebar from '../../../Components/Sidebar';
import { initializeUserStore, put } from '../../../utils/api';
import { useStores } from '../../../Hooks/useStores';
import withJamesAuthenticator from '../../../utils/withJamesAuthenticator';

const UserPage = ({user}) => {
    const loading = useStores(user);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    const { userId } = useParams();
    const thisUser = useSelector(state => state.users.users.find(u => u.Username === userId))

    useEffect(() => {
        if(thisUser) {
            setEmail(thisUser.email)
            setName(thisUser.name)
            setCompanyName(thisUser['custom:company_name']) 
        }
    },[thisUser])

    if (loading) return loading;

    if(!thisUser) {
        toast.error('User not found.');
        return <Navigate to="/admin/user"/>
    }

    const handleEditUser = () => {
        console.log('Editing User')
        console.log('With fields')

        setShowErrors(true);
        if (name && email && companyName) {
            setShowErrors(true);
            var attributesToEdit = [{'Name':'email','Value':email},{'Name':'name','Value':name},{'Name':'custom:company_name','Value':companyName}]
            put(`/admin/users/${thisUser.Username}`,{'Username': thisUser.Username,'attributesToUpdate': attributesToEdit})
            .then(response =>{
                toast.success(`User ${thisUser.name} has been successfully edited.`)
                initializeUserStore()
            })
            .catch(error =>{
                toast.error(`Error when editing user ${thisUser.name}: ${error.response.data}`)
            })
        }
    }

    const handleDisableUser = () => {
        put(`/admin/users/${thisUser.Username}/enable`)
        .then(response =>{
            toast.success(`${response}`)
            initializeUserStore()
        })
        .catch(error =>{
            if (thisUser.Enabled){
                toast.error(`Error when trying to disable user ${thisUser.name}: ${error.response.data}`)
            }
            else{
                toast.error(`Error when trying to enable user ${thisUser.name}: ${error.response.data}`)
            }
        })
    }
    const handleResendMailToUser = () => {
        var userstToResendMailTo = thisUser
        console.log('ResendEmail')
        console.log(userstToResendMailTo)
    }

    const buttonAlertDialogItems = [
        {
            buttonText: thisUser.Enabled ? "Disable user" : "Enable user",
            alertMsg: {
                    body: `Are you sure you want to ${thisUser.Enabled ? "disable" : "enable"} the following user: ${thisUser.email}`,
                    title: thisUser.Enabled ? "Disable user" : "Enable user"
            },
            callbackFunction: handleDisableUser,
        },
        {
            buttonText: "Resend verification mail",
            alertMsg: {
                    body:`Are you sure you want to resend a verifcation mail to the following user: ${thisUser.email}`,
                    title: 'Resend verification mail'
            },
            callbackFunction: handleResendMailToUser,
        },
        {
            buttonText: 'Edit User',
            alertMsg: {
                    body:`Are you sure you want to edit the following user: ${thisUser.email}`,
                    title:  `Edit user ${thisUser.email}`,
            },
            callbackFunction: handleEditUser,
        },
    ]

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box>
                <Typography>User details of user with id: {thisUser.name}</Typography>
                        <Box component="form" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1, display: 'flex', maxWidth: 600, flexWrap: 'wrap'}}>
                            <TextField 
                                sx={{m:1, flexBasis: '100%'}}
                                label="Name" 
                                variant="outlined" 
                                value={name}
                                error={showErrors && !name}
                                onChange={(e) => setName(e.target.value)}
                                />
                            <TextField 
                                sx={{m:1, flexBasis: '100%'}}
                                label="Email" 
                                variant="outlined" 
                                value={email}
                                error={showErrors && !email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField 
                                sx={{m:1, flexBasis: '100%'}}
                                label="Company Name" 
                                variant="outlined" 
                                value={companyName}
                                error={showErrors && !companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                            {buttonAlertDialogItems.map(buttonAlertDialogItem => {
                                return <AlertDialogButton buttonText={buttonAlertDialogItem.buttonText} alertMsg={buttonAlertDialogItem.alertMsg} callbackFunction={buttonAlertDialogItem.callbackFunction}/>    
                            })
                        }
                        <Box/>
                </Box>
            </Box>
        </Box>
    );
};

export default withJamesAuthenticator(UserPage);