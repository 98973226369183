import { Button, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DocumentRenderer from '../../Components/DocumentRenderer';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import { useStores } from '../../Hooks/useStores';
import { getUserDocument } from '../../utils/api';
import withJamesAuthenticator from '../../utils/withJamesAuthenticator';

import './Document.scss';

const Document = ({user}) => {
    useStores(user)
    const { documentId } = useParams();
    const navigate = useNavigate();

    const document = useSelector(state => state.documentsOfUser.documentsOfUser).find(x => x.documentId === documentId)

    useEffect(() => {
        if (!document || !document.presignedUrl) {
            try {
                getUserDocument(documentId)
            } catch (error) {
                toast.error("Investeringskans niet gevonden.");
                navigate('/portal');

            }
        }
    } , [documentId, document, navigate])

    if (!document) {
        return (
            <div>
                <div className="Page">
                    <div className="Page__loading">
                        <CircularProgress />
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
    
    const expiresAt = (new Date(document.expiresAt)).toLocaleDateString();
    
    return (
        <div className="Wrapper" style={{backgroundImage: `url('/img/waagplein.jpg')`, minHeight: '100vh'}}>
            <Header/>
            <div className='Page'>
                <div className="Document">
                    <div className="Document__info">
                        <Button variant="contained" onClick={() => navigate('/portal')}>
                            Terug naar overzicht
                        </Button>
                        <h1>{document.title}</h1>
                        <p>{document.description}</p>
                        <Button variant="contained" target="_blank" href={`mailto:hallo@ikbenjames.com?subject=Interesse%in%20investeringsmogelijkheid%20${document.title}&body=Beste%2C%0D%0A%0D%0AIk%20ben%20ge%C3%AFnteresseerd%20in%20de%20investeringsmogelijkheid%20${document.title}.%0D%0A%0D%0AGroet%2C`}>
                            Ik ben geïnteresseerd
                        </Button>

                        <p><b>Verloopt op:</b> {expiresAt}</p>
                    </div>
                    <div className="Document__content">
                        <DocumentRenderer document={document} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withJamesAuthenticator(Document);