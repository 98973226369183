import React from 'react';
import Header from '../../Components/Header';
import Jumbotron from '../../Components/Jumbotron';
import DocumentList from '../../Components/User/DocumentList';
import UserMenu from '../../Components/User/UserMenu';
import { useStores } from '../../Hooks/useStores';
import withJamesAuthenticator from '../../utils/withJamesAuthenticator';

import '../Home.scss';

const Home = ({user}) => {
    useStores(user)

    return (
        <div className='Home' style={{backgroundImage: `url('/img/waagplein.jpg')`, minHeight: '100vh'}}>
            <div className='Page'>
                <Header/>
                <UserMenu/>
                <Jumbotron title="Ik ben James" subtitle={`Hallo, ${user.attributes.name}!`}>
                    <p>James is jouw investeringsbutler en reikt investeringskansen aan op een besloten platform voor investeerders, simpel, direct en no nonsens.</p> 
                    <p>Via james worden investeerders op de hoogte gehouden van nieuwe investeringskansen. Deze kansen zijn allemaal voorbereid door de specialisten van de fcg adviesgroep, waar onder andere Financieringsgilde Noord Holland en fcg. corporate finance onder vallen.</p>
                    <p>Op deze pagina vindt u uw investeringskansen. Bekijk ze snel!</p>
                </Jumbotron>
                <DocumentList/>
            </div>
        </div>
    );
};

export default withJamesAuthenticator(Home);