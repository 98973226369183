import { UserActions } from '../actions/users'

const initialState = {
    users: [],
    initialized: false,
}

export const users = (state = initialState, action) => {
    switch (action.type) {
        case UserActions.setUsers:
            return {...state, users: action.data}
        case UserActions.addUser:
            return {...state, users: [...state.users, action.data]}
        case UserActions.removeUser:
            return {...state, users: state.users.filter(user => user.id !== action.data.id)}
        case UserActions.setUserInitializationStatus:
            return {...state, initialized: action.data}
        default:
            return state
    }
}
