import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { initializeDocumentsOfUserStore, initializeDocumentStore, initializeUserStore } from "../utils/api"
import { userHasGroup } from "../utils/authUtil"

export function useStores(user){
    const [loading, setLoading] = useState(true)

    const storesInitialized = useSelector(state => {
      const keysOfObj = Object.keys(state);
      return keysOfObj.every(keyOfObj => state[keyOfObj].initialized) ?? false
    })

    useEffect(() => {
        const initialize = async () => {
          if (user && !storesInitialized){
            await initializeDocumentsOfUserStore()
          }

          if (user && userHasGroup(user,'Admins') && !storesInitialized){
            await initializeUserStore()
            await initializeDocumentStore()
          }
    
          return
        }
        
        initialize().then(() => setLoading(false))
      }, [user, storesInitialized])

    if(loading) return <Box sx={{width: '100vw', height:'100vh', display: 'flex', justifyContent:'center', alignItems:'center'}}><CircularProgress /></Box>
}