import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { userHasGroup } from '../../utils/authUtil';
import withJamesAuthenticator from '../../utils/withJamesAuthenticator';

const PrivateRoute = (props) => {
    const { user, roles } = props;

    const hasRoles = roles.some(role => userHasGroup(user, role));

    return hasRoles? <Outlet/> : <Navigate to="/portal" />;
};

export default withJamesAuthenticator(PrivateRoute);