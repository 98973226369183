import * as React from 'react';
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';

// props should be of the type, {status, statusToDisplay, icon}
const StatusChip = ({ status, statusToDisplay, icon, color, tooltipText = "", onClick = null}) => {
  // const { status, statusToDisplay, icon, color, tooltipText} = props.input
  return (
    <Tooltip title={tooltipText} placement="right" arrow>
      <Chip onClick={onClick} sx={{px:1, py:2, fontWeight: 'bold', textTransform: 'uppercase'}} className={status} icon={icon} size="small" color={color} label={statusToDisplay} />
    </Tooltip>
  );
};

export default StatusChip