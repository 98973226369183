import React, { useState } from 'react';
import { Avatar, Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import { documentStatuses } from '../../../Constants/documentStatuses';

import { toast } from 'react-toastify';

import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

import Dropzone from 'react-dropzone'

import Sidebar from '../../../Components/Sidebar';
import { initializeDocumentStore, post } from '../../../utils/api';
import { Storage } from 'aws-amplify';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    margin: '0.5rem',
    padding: '2rem',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const DocumentCreate = () => {
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(documentStatuses[0].value);
    const [expiresAt, setExpiresAt] = useState();
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const [showErrors, setShowErrors] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(disabled) return;
        setShowErrors(true);

        if (title && status && expiresAt && description && file) {
            setShowErrors(false);

            const fileName = title.replace(/\s/g, '_').toLowerCase() + '_' + Date.now() + '.' + file.name.split('.').pop();

            setDisabled(true);
            Storage.put(fileName, file, {
                contentType: file.type,
            }).then(result => {
                // setFileKey(result.key);
                // TODO: Upload to Dynamo DB
                post('/admin/documents', {
                    'DocumentId': title,
                    'Title': title,
                    'Description': description,
                    'Status': status,
                    'ExpiresAt': expiresAt,
                    'FilePath': result.key
                }).then(result => {
                    console.log(result);
                    toast.success('Document created successfully');
                    setDisabled(false);
                    initializeDocumentStore();
                }).catch(err => {
                    toast.error('Error creating document');
                    setDisabled(false);
                })
            })
            return
        }
        toast.error('Not all required field are filled in.');

        if(!file) {
            toast.error('Please select a file.');
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box component="main" sx={{p:3}}>
                <Typography variant="h3" sx={{p:1}}>
                    Create Document
                </Typography>
                <Box component="form" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1, display: 'flex', maxWidth: 600, flexWrap: 'wrap'}}>
                    <TextField 
                        sx={{m:1, flexBasis: '100%'}}
                        label="Title" 
                        variant="outlined" 
                        value={title}
                        error={showErrors && !title}
                        onChange={(e) => setTitle(e.target.value)}
                        />
                    <TextField
                        select
                        sx={{m:1, flex: 1}}
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        error={showErrors && !status}
                        >
                        {documentStatuses.map(({value, label}) => (
                            <MenuItem key={value} value={value}>
                            {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Expires At"
                        type="datetime-local"
                        sx={{m:1, flex: 1}}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        value={expiresAt}
                        onChange={(e) => setExpiresAt(e.target.value)}
                        error={showErrors && !expiresAt}
                    />
                    <TextField
                        label="Description"
                        multiline
                        sx={{m:1, flexBasis: 600}}
                        rows={4}
                        value={description}
                        error={showErrors && !description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div style={{flexBasis: '100%'}}>
                        <Typography sx={{ mt: 1 }} variant="h6" component="div">
                            Document
                        </Typography>
                        {/* TODO: Dropzone error */}
                        <Dropzone onDrop={(files) => setFile(files[0])}  multiple={false} accept={{"application/pdf": ['.pdf']}}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()} style={baseStyle}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop a file here, or click to select a file</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <List>
                            {file && (
                                <ListItem secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => setFile(undefined)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    }>
                                    <ListItemAvatar>
                                        <Avatar>
                                        <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={file.name} />
                                </ListItem>
                            )}
                        </List>
                    </div>
                    <Button sx={{ m: 1 }} type="submit" disabled={disabled} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DocumentCreate;