import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import UsersIcon from '@mui/icons-material/People';
import DocumentsIcon from '@mui/icons-material/Folder';

import './UserMenu.scss';
import { useNavigate } from 'react-router-dom';
import { userHasGroup } from '../../../utils/authUtil';
import { useCustomSignOut } from '../../../Hooks/useCustomSignOut';
import withJamesAuthenticator from '../../../utils/withJamesAuthenticator';

const UserMenu = ({user}) => {
    const customSignOut = useCustomSignOut();
    const navigate = useNavigate();
    const isAdmin = userHasGroup(user, 'Admins')

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const firstLetter = user.attributes.name.charAt(0);

    let menuItems = []

    if (isAdmin) {
        menuItems.push(
            <MenuItem key="admin-user" onClick={() => navigate('/admin/user')}>
                <ListItemIcon>
                    <UsersIcon />
                </ListItemIcon>
                Users
            </MenuItem>
        )

        menuItems.push(
            <MenuItem key="admin-document" onClick={() => navigate('/admin/document')}>
                <ListItemIcon>
                    <DocumentsIcon />
                </ListItemIcon>
                Documents
            </MenuItem>
        )
        menuItems.push(<Divider key="divider-1"/>)
    }

    menuItems.push(
        <MenuItem onClick={customSignOut}>
        <ListItemIcon>
            <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Logout
        </MenuItem>
    )

    return (
        <>
        <div className='UserMenu'>
            <Tooltip title={user.attributes.name}>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ mr: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar>{firstLetter}</Avatar>
            </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    mr: -0.5,
                    ml: 1,
                    },
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {
                    menuItems
                }
            </Menu>
        </div>
        </>
    );
};

export default withJamesAuthenticator(UserMenu);