import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { documentStatuses } from '../../../Constants/documentStatuses';

import { toast } from 'react-toastify';

import Sidebar from '../../../Components/Sidebar';
import { useStores } from '../../../Hooks/useStores';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { initializeDocumentStore, put } from '../../../utils/api';

const DocumentPage = ({document}) => {
    const loading = useStores(document);
    let navigate = useNavigate(); 

    const [title, setTitle] = useState('');
    const [status, setStatus] = useState(documentStatuses[0].value);
    const [expiresAt, setExpiresAt] = useState();
    const [description, setDescription] = useState('');

    const [disabled, setDisabled] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const { documentId } = useParams();
    const thisDocument = useSelector(state => state.documents.documents.find(d => d.documentId === documentId))

    useEffect(() => {
        if (thisDocument) {
            setTitle(thisDocument.title);
            setStatus(thisDocument.status);
            setExpiresAt(thisDocument.expiresAt);
            setDescription(thisDocument.description);
        }
    }, [thisDocument]);

    const navigateToDocumentUsers = () => {
        navigate(`/admin/document/${documentId}/users`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(disabled) return;
        // setShowErrors(true);
        if (title && status && expiresAt && description) {
            setShowErrors(false);

            setDisabled(true);
            put(`/admin/documents/${documentId}`, {
                'DocumentId': documentId,
                'Title': title,
                'Description': description,
                'Status': status,
                'ExpiresAt': expiresAt
            }).then(result => {
                console.log(result);
                toast.success('Document updated successfully');
                setDisabled(false);
                
                initializeDocumentStore();
            }).catch(err => {
                toast.error('Error updating document');
                setDisabled(false);
            })
            return
        }

        toast.error('Not all required field are filled in.');
    }

    if (loading) return loading;
    
    if(!thisDocument) {
        toast.error('Document not found.');
        return <Navigate to="/admin/document"/>
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box component="main" sx={{p:3}}>
                <Typography variant="h3" sx={{p:1}}>
                    Assign Users
                </Typography>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Button sx={{ m:'1em'}} variant='contained' onClick={navigateToDocumentUsers}>Assign users</Button>
                </Box>
                <Typography variant="h3" sx={{p:1}}>
                    Update Document
                </Typography>
                <Box component="form" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 1, display: 'flex', maxWidth: 600, flexWrap: 'wrap'}}>
                    <TextField 
                        sx={{m:1, flexBasis: '100%'}}
                        label="Title" 
                        variant="outlined" 
                        value={title}
                        error={showErrors && !title}
                        onChange={(e) => setTitle(e.target.value)}
                        />
                    <TextField
                        select
                        sx={{m:1, flex: 1}}
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        error={showErrors && !status}
                        >
                        {documentStatuses.map(({value, label}) => (
                            <MenuItem key={value} value={value}>
                            {label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Expires At"
                        type="datetime-local"
                        sx={{m:1, flex: 1}}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        value={expiresAt}
                        onChange={(e) => setExpiresAt(e.target.value)}
                        error={showErrors && !expiresAt}
                    />
                    <TextField
                        label="Description"
                        multiline
                        sx={{m:1, flexBasis: 600}}
                        rows={4}
                        value={description}
                        error={showErrors && !description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <Button sx={{ m: 1 }} type="submit" disabled={disabled} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DocumentPage;