export const documentStatuses = [
    {
        value: 'DRAFT',
        label: 'Draft',
        color: 'warning',
        description: 'The document is not yet visible for users.'
    },
    {
        value: 'PUBLISHED',
        label: 'Published',
        color: 'success',
        description: 'The document is visible for users.'
    },
    {
        value: 'ARCHIVED',
        label: 'Archived',
        color: 'danger',
        description: 'The document is not visible for users.'
    }
]