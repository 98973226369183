export const UserActions = {
    setUsers: '@users/setUsers',
    addUser: '@users/addUser',
    removeUser: '@users/removeUser',
    setUserInitializationStatus: '@users/setUserInitializationStatus',
}

export const setUsers = (users) => {
    return dispatch => dispatch({
        type: UserActions.setUsers,
        data: users
    })
}

export const setUserInitializationStatus = (initialized) => {
    return dispatch => dispatch({
        type: UserActions.setUserInitializationStatus,
        data: initialized
    })
}