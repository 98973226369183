import { Box, Pagination, Paper } from "@mui/material";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentRenderer = ({document}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
        setLoading(false);
    }
    
    return (
        <Box sx={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <Paper sx={{p:3, m:2}}>
                <Document file={document.presignedUrl} onLoadSuccess={onDocumentLoadSuccess.bind(this)} >
                    <Page pageNumber={pageNumber} />
                </Document>
            </Paper>
            <Pagination 
            sx={{
                '& .MuiPaginationItem-root:not(.Mui-selected)': {
                    backgroundColor: '#BC922DAE'
                }
            }}
            color="primary" count={numPages} page={pageNumber} onChange={(event, value) => setPageNumber(value)} loading={loading}/>
        </Box>
    )
}

export default DocumentRenderer;