import { Amplify } from 'aws-amplify';
import {amplify_config} from './.aws-config';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import DocumentCreate from './Pages/Admin/Document/DocumentCreate';
import DocumentUsers from './Pages/Admin/Document/DocumentUsers';
import UserCreate from './Pages/Admin/User/UserCreate';
import UserList from './Pages/Admin/User/UserList';
import UserPage from './Pages/Admin/User/UserPage';
import DocumentList from './Pages/Admin/Document/DocumentList';
import Home from './Pages/Home';
import { default as UserHome } from './Pages/User/Home';

import './Styling/style.scss'
import Document from './Pages/User/Document';
import Contact from './Pages/Contact';
import PrivateRoute from './Components/PrivateRoute';
import DocumentPage from './Pages/Admin/Document/DocumentPage';

Amplify.configure(amplify_config);

function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/portal" element={<UserHome/>}/>
        <Route path="/document/:documentId" element={<Document />} />

        <Route exact path='/admin' element={<PrivateRoute roles={['Admins']}/>}>
          <Route path="document" element={<DocumentList />} />
          <Route path="document/:documentId" element={<DocumentPage />} />
          <Route path="document/:documentId/users" element={<DocumentUsers />} />
          <Route path="document/create" element={<DocumentCreate />} />
          <Route path="user/create" element={<UserCreate />} />
          <Route path="user" element={<UserList />} />
          <Route path="user/:userId" element={<UserPage />} />
          {/* Fallback redirect to document/create */}
          <Route path="*" element={<Navigate to="/admin/document/create" />} />
        </Route>
      </Routes>
      </BrowserRouter>
      <ToastContainer 
      theme="colored" 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      progress={undefined}
      />
    </>
  );
}

export default App;