import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Sidebar from '../../../Components/Sidebar';
import { initializeUserStore, post } from '../../../utils/api';

import { toast } from 'react-toastify';

const UserCreate = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [companyName, setCompanyName] = useState('');

    const [showErrors, setShowErrors] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()

        if(disabled) return;
        setShowErrors(true);
        if (name && email && companyName) {
            setShowErrors(false);

            setDisabled(true);
            post('/admin/users', {name:name,email:email,company_name:companyName}).then(result => {
                toast.success('User created successfully');
                setDisabled(false);

                initializeUserStore();
            }).catch(err => {
                toast.error('Error creating user');
                setDisabled(false);
            })
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            {/* <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}> */}
            <Box component="form" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, display: 'flex', maxWidth: 600, flexWrap: 'wrap'}}>
                <p> Users will get sent a single-sign on mail.</p>
                <TextField
                    sx={{m:1, flexBasis: '100%'}}
                    label="Email" 
                    variant="outlined" 
                    value={email}
                    error={showErrors && !email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                <TextField
                    sx={{m:1, flexBasis: '100%'}}
                    label="Name" 
                    variant="outlined" 
                    value={name}
                    error={showErrors && !name}
                    onChange={(e) => setName(e.target.value)}
                    />
                <TextField
                    sx={{m:1, flexBasis: '100%'}}
                    label="Company Name" 
                    variant="outlined" 
                    value={companyName}
                    error={showErrors && !companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    />
                <Button sx={{ m: 1 }} disabled={disabled} type="submit" variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
            </Box>
        </Box>
    );
};

export default UserCreate;