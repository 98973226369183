import React from 'react';
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';

import { NavLink, useLocation } from 'react-router-dom';
import {userHasGroup } from '../../utils/authUtil';
import { useSelector } from 'react-redux';
import { useStores } from '../../Hooks/useStores';
import { useCustomSignOut } from '../../Hooks/useCustomSignOut';
import withJamesAuthenticator from '../../utils/withJamesAuthenticator';

const Sidebar = ({user}) => {

    useStores(user);
    const customSignOut = useCustomSignOut();

    const isAdmin =  userHasGroup(user, 'Admins')
    const documentsOfUser = useSelector(state => state.documentsOfUser.documentsOfUser)

    const drawerWidth = 300;
    const { pathname } = useLocation();

    const navItems = [
        {
            name: 'Home',
            key: 'home',
            icon: <HomeIcon/>,
            to: '/',
        },
    ];

    const userNavItems = documentsOfUser.map(d => {
        return {name:d.title, key:d.documentId, icon: <StarIcon/>, to:`/${d.documentId}`}
    })

    const adminNavItems = [
        {
            name: 'Create Document',
            key: 'create-document',
            icon: <PostAddIcon/>,
            to: '/admin/document/create',
        },
        {
            name: 'List Documents',
            key: 'list-document',
            icon: <PostAddIcon/>,
            to: '/admin/document',
        },
        {
            name: 'Create User',
            key: 'create-user',
            icon: <GroupIcon/>,
            to: '/admin/user/create',
        },
        {
            name: 'List Users',
            key: 'list-user',
            icon: <GroupIcon/>,
            to: '/admin/user/',
        }
    ]
    
    return (
        <Drawer variant="permanent" anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}>
            <img style={{padding: 16}} src="/img/ikbenjames.png" alt="Ik ben James logo" />
            <Divider />
            <List>
                {/* TODO: clear admin states on singout */}
                <ListItemButton onClick={customSignOut}>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItemButton>
            </List>
            <Divider />
            <List component="nav">
                {
                    navItems.map(navItem => {
                        return (
                            <ListItemButton component={NavLink} to={navItem.to} selected={pathname === navItem.to} key={navItem.key}>
                                <ListItemIcon>{navItem.icon}</ListItemIcon>
                                <ListItemText primary={navItem.name} />
                            </ListItemButton>
                        );
                    })
                }
            </List>
            <Divider />
            {isAdmin ? 
            <>
              <List component="nav"
                subheader={<ListSubheader>Admin</ListSubheader>}>
                {
                    adminNavItems.map(navItem => {
                        return (
                            <ListItemButton component={NavLink} to={navItem.to} selected={pathname === navItem.to} key={navItem.key}>
                                <ListItemIcon>{navItem.icon}</ListItemIcon>
                                <ListItemText primary={navItem.name} />
                            </ListItemButton>
                        );
                    })
                }
            </List></> :
            <>
                <List component="nav"
                subheader={<ListSubheader>Investment Opportunities</ListSubheader>}>
                {
                    userNavItems.map(navItem => {
                        return (
                            <ListItemButton component={NavLink} to={navItem.to} selected={pathname === navItem.to} key={navItem.key}>
                                <ListItemIcon>{navItem.icon}</ListItemIcon>
                                <ListItemText primary={navItem.name} />
                            </ListItemButton>
                        );
                    })
                }
            </List></>
            }
        </Drawer>
    );
};

export default withJamesAuthenticator(Sidebar);