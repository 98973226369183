import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Sidebar from '../../../Components/Sidebar';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import ArchiveIcon from '@mui/icons-material/Archive';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StatusChip from '../../../Components/StatusChip';
import { DataGrid } from '@mui/x-data-grid';

import BlockIcon from '@mui/icons-material/Block';
import AlertDialogComponent from '../../../Components/AlertDialogComponent';

import { get, put } from '../../../utils/api';

import { useStores } from '../../../Hooks/useStores';
import withJamesAuthenticator from '../../../utils/withJamesAuthenticator';

const DocumentUsers = ({user}) => {

    const loading = useStores(user);

    const { documentId } = useParams();

    const [selectedToAssignUsers, setSelectedToAssignUsers] = useState([]);
    
    const userList = useSelector(state => state.users.users)
    const [assignedUsers, setAssignedUsers] = useState([]);

    const users = userList.map(user => {
        const assignedUser = assignedUsers.find(assignedUser => assignedUser.UserId === user.Username);
        return {
            ...user,
            assigned: assignedUser ? true : false,
            status: assignedUser ? assignedUser.Status : '',
            lastOpenedAt: assignedUser ? assignedUser.LastOpenedAt : '',
        }
    }).filter(user => user.Enabled)

    console.log(users)

    useEffect(() => {
        get(`/admin/documents/${documentId}/assignedUsers`)
        .then(userDocumentEntries =>{
            console.log(userDocumentEntries)
            setAssignedUsers(userDocumentEntries)
            toast.success(`Sucessfully fetched all users assigned to document ${documentId}`)
        })
        .catch(error =>{
            toast.error(`Error when fetching all users assigned to document ${documentId}:  ${error.response.data.message}`)
        })
    },[documentId])

    const thisDocument = useSelector(state => state.documents.documents.find(u => u.documentId === documentId))
    
    const userStatusMapper = (userStatus) => {
        if (userStatus === "CONFIRMED"){
            return {status:userStatus, statusToDisplay: 'Confirmed', icon: <ThumbUpAltIcon/ >, color:"success",tooltipText :"User has been confirmed."}
        }
        else if (userStatus === "FORCE_CHANGE_PASSWORD"){
            return {status:userStatus, statusToDisplay: "OTP sent", icon: <SendIcon />, color:"info",tooltipText :"The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else."}
        }
        else if (userStatus === "EXTERNAL_PROVIDER"){
            return {status:userStatus, statusToDisplay: "External Provider", icon: <LinkIcon/>, color:"info",tooltipText :"User signed in with a third-party IdP."}
        }
        else if (userStatus === "ARCHIVED"){
            return {status:userStatus, statusToDisplay: "Archived", icon: <ArchiveIcon/>, color:"warning",tooltipText :"User is no longer active."}
        }
        else if (userStatus === "UNKNOWN"){
            return {status:userStatus, statusToDisplay: "Unknown", icon: <QuestionMarkIcon/>, color:"info",tooltipText :"User status isn't known."}
        }
        else if (userStatus === "RESET_REQUIRED"){
            return {status:userStatus, statusToDisplay: "Reset Required", icon: <RestartAltIcon/>, color:"info",tooltipText:"User is confirmed, but the user must request a code and reset their password before they can sign in"}
        }
        else if (userStatus === "UNCONFIRMED"){
            return {status:userStatus, statusToDisplay: "Unconfirmed", icon: <CancelIcon/>, color:"warning",tooltipText :"User has been created but not confirmed."}
        }
        return {status: userStatus, valueToDisplay: userStatus, icon: <div/>}
    }

    const columns = [
        // { field: 'Username', headerName: 'ID', width: 130 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'custom:company_name', headerName: 'Company Name', width: 200},
        {
            field:"UserStatus",
            align:"center",
            headerName:"User status",
            width:150,
            renderCell({ row }){
            return <StatusChip {...userStatusMapper(row.UserStatus)} />
            }
        },
        {
            field:"status",
            align:"center",
            headerName:"Access",
            width:150,
            renderCell({ row }){
                if (!row.status) return;
                const component = {reactComponent:StatusChip, props:userDocumentStatusMapper(row.status)}
                const alertMsgBodyText = row.status === "Enabled" ? 'disable' : 'enable'
                return <AlertDialogComponent 
                component={component} 
                alertMsg={
                    {
                        title:"",
                        body:`Are you sure you want to ${alertMsgBodyText} the access of user ${row.name} to the current document?`}
                } 
                callbackFunction={() => handleEnableUserDocument(row.Username, !(row.status === "Enabled"))}
                />
            }
         },
         {
            field:"lastOpenedAt",
            headerName:"Last Opened At",
            align:"left",
            width:170,
            renderCell({ row }){
                return (row.assigned && row.lastOpenedAt) ? 
                    new Date(row.lastOpenedAt).toLocaleString() : "Never" 
            }
         }
    ];

    if (loading) return loading;
    
    if(!thisDocument) {
        toast.error('Document not found.');
        return <Navigate to="/admin/document"/>
    }

    const userDocumentStatusMapper = (status) => {
        if (status === "Enabled"){
            return {status:status, statusToDisplay: 'Enabled', icon: <ThumbUpAltIcon/>, color:"success",tooltipText :"User is enabled to see this document."}
        }
        else if (status === "Disabled"){
            return {status:status, statusToDisplay: 'Disabled', icon: <BlockIcon/>, color:"error",tooltipText :"User has been disabled to see this document."}
        }
        return {status: status, valueToDisplay: status, icon: <div/>}
    }

    const handleEnableUserDocument = (userId, state) => {
        put(`/admin/documents/${documentId}/enable/${userId}`, {enabled: state})
        .then(response =>{
            toast.success(`Sucessfully ${state ? 'enabled' : 'disabled'} user ${userId} access to document ${documentId}`)
            get(`/admin/documents/${documentId}/assignedUsers`)
            .then(userDocumentEntries =>{setAssignedUsers(userDocumentEntries)})
        })
        .catch(error =>{
            toast.error(`Error when ${state ? 'enabling' : 'disabling'} user ${userId} access to document ${documentId}:  ${error.response.data}`)
        })
    }

    const handleAssignUsers = () => {
        // TODO: remove unassigned users
        put(`/admin/documents/${documentId}/assign`, {
            'Users':selectedToAssignUsers
        }).then(result =>{
            toast.success("Successfully assigned users to document")
            get(`/admin/documents/${documentId}/assignedUsers`)
            .then(userDocumentEntries =>{setAssignedUsers(userDocumentEntries)})
        }).catch(err =>{
            toast.error(`Error when assigning users to document: ${err.response.data}`)
        })
        setSelectedToAssignUsers([])
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box sx={{width:'140vh'}}>
                <Box sx={{height:'100vh', overflowY:'scroll',
                '& .success': {
                    bgcolor: '#cefad0',
                },
                '& .danger': {
                    bgcolor: '#ffcccb',
                }}}>
                    <DataGrid 
                        getRowId = {(row) => row.Username}
                        sx = {{flex: 1}}
                        rows = {users}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'status', sort: 'desc' }],
                            }
                        }}
                        checkboxSelection
                        columns = {columns}
                        rowsPerPageOptions={[5]}
                        isRowSelectable={({row}) => {return !row.assigned}}
                        onSelectionModelChange={(entry) => setSelectedToAssignUsers(entry)}
                        getRowClassName={({row}) => {return row.assigned ? (row.status === 'Enabled') ? 'success' : 'danger' : ''}}
                        components = {{
                            Footer: () => {
                                return <Stack sx={{justifyContent:'center', m:'1em'}}>
                                    <Button sx={{ m:'1em'}} variant='contained' disabled={selectedToAssignUsers.length === 0} onClick={handleAssignUsers}>Invite users</Button>
                                </Stack>
                            }
                        }}
                        />
                </Box>
            </Box>
        </Box>
    );
};

export default withJamesAuthenticator(DocumentUsers);