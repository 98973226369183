import { Image, useTheme, View, withAuthenticator } from "@aws-amplify/ui-react";

const components = {
    SignIn: {
        Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large} >
                <Image
                    alt="Amplify logo"
                    src={process.env.PUBLIC_URL + "/img/ikbenjames.png"}
                />
            </View>
        );
        }
    }
}

// Create a new component that is "withAuthenticator" enhanced
const withJamesAuthenticator = (Component) => {
    return withAuthenticator(Component, {
        // Hide the default signup form
        hideSignUp: true,
        // Customize the UI
        components
    });
}

export default withJamesAuthenticator;