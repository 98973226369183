export const DocumentActions = {
    setDocuments: '@documents/setDocuments',
    addDocument: '@documents/addDocument',
    removeDocument: '@documents/removeDocument',
    setDocumentInitializationStatus: '@documents/setDocumentInitializationStatus',
}

export const setDocuments = (documents) => {
    return dispatch => dispatch({
        type: DocumentActions.setDocuments,
        data: documents
    })
}

export const setDocumentInitializationStatus = (initialized) => {
    return dispatch => dispatch({
        type: DocumentActions.setDocumentInitializationStatus,
        data: initialized
    })
}