import React from 'react';
import Sidebar from '../../../Components/Sidebar';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StatusChip from '../../../Components/StatusChip';

import { documentStatuses } from '../../../Constants/documentStatuses';
import { Box, Button } from '@mui/material';

const columns = [
    { field: 'title', headerName: 'Title', width: 130 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'expiresAt', headerName: 'Expires At', width: 200 },
    {
        field:"status",
        align:"center",
        headerName:"Status",
        width:150,
        renderCell({ row }){
           return <StatusChip {...documentStatusMapper(row.status)} />
        }
     },
  ];

const documentStatusMapper = (documentStatus) => {
    let status = documentStatuses.find(status => status.value === documentStatus)
    if(status) {
        return {status: status.value, statusToDisplay: status.label, icon: <div/>, color: status.color, tooltipText: status.description}
    }
    return {status: documentStatus, statusToDisplay: documentStatus, icon: <div/>, tooltipText:""}
}

const DocumentList = () => {
    const documentList = useSelector(state => state.documents.documents)

    console.log(documentList)
    let navigate = useNavigate(); 
    const routeChangeToDocumentPage = (documentId) => { 
        console.log(documentId)
        let path = `/admin/document/${documentId}`; 
        navigate(path);
    }
    
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar/>
            <Box sx= {{height: 500, flex: 1}}>
                <DataGrid 
                    getRowId = {(row) => row.documentId}
                    rows = {documentList}
                    columns = {columns}
                    pagesize={2}
                    rowsPerPageOptions={[5]}
                    onRowDoubleClick={e => routeChangeToDocumentPage(e.id)}
                    components={{ Toolbar: GridToolbar }}
                />
            <Button sx={{ m: 1 }} type="submit" variant="contained" color="primary" onClick={() => navigate('/admin/document/create')}>
                Create document
            </Button>
            </Box>
        </Box>
    );
};

export default DocumentList;